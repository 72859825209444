import * as React from "react"
import { useRef, useState } from "react";
import { Link } from "gatsby"

const TitleBlockStandard = ({ pageTitle1, pageTitleBody1, pageTitle2, pageTitleBody2, }) => {
  





  return (
    <div
      className="container-fluid py-5 py-md-0 titleBlock"
    >
      <div className="container">
        <div className="row d-flex flex-column flex-md-row">
         

          
                  <div className="col " >
              <div className="row" >
                <div className="col bg-light">
                  <div className="py-md-5 my-md-5">
                    <div className="py-5 my-5"
                      data-sal-duration="500"
                      data-sal="slide-up"
                      data-sal-delay="100"
                      data-sal-easing="ease"
                    >
                  
                    <h2
                      className="fw-light fs-3 my-4"
                      dangerouslySetInnerHTML={{ __html: pageTitle1 }}
                    />
                    <p className="fw-normal"
                      dangerouslySetInnerHTML={{ __html: pageTitleBody1 }}
                    />
   
                    </div>
                  </div>
              </div>
            </div>
                  </div>
                  
                      <div className="col " >
              <div className="row" >
                <div className="col">
                  <div className="py-md-5 my-md-5">
                    <div className="py-5 my-5"
                      data-sal-duration="500"
                      data-sal="slide-up"
                      data-sal-delay="300"
                      data-sal-easing="ease"
                    >
                  
                    <h2
                      className="fw-light fs-3 my-4"
                      dangerouslySetInnerHTML={{ __html: pageTitle2 }}
                    />
                    <p className="fw-normal"
                      dangerouslySetInnerHTML={{ __html: pageTitleBody2 }}
                    />
   
                    </div>
                  </div>
              </div>
            </div>
          </div>

         

          

          </div>
        </div>
      </div>

    )
}

export default TitleBlockStandard
