import * as React from "react"
import { useRef, useEffect,useState } from "react";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

const TitleBlockStandard = ({ pageTitle, pageTitleBody, img}) => {
  
  const loadedImage = getImage(img)
  const bgImage = convertToBgImage(loadedImage);
  const ref = useRef(null);


  





  const [height, setHeight] = useState();


    useEffect(() => {
      const element = ref.current;

      const outerHeight = (el) => {
        var height = el.offsetHeight;
        var style = window.getComputedStyle(el);
        height += parseInt(style.marginTop) + parseInt(style.marginBottom);
        return height;
      };
      
      function handleResizeTitleBlock() {
        let h = outerHeight(element);
        setHeight(h);
      }

      handleResizeTitleBlock();
      window.addEventListener('resize', handleResizeTitleBlock)

      return () => {
        };
        
    }, []);

  return (
    <div
      className="container-fluid bg-light py-5 py-md-0 titleBlock"
      style={{  minHeight:  height  +'px' }}
    >
      <div className="container">
        <div className="row d-flex flex-column flex-md-row">
         
          <div
            className="col position-relative p-0"
            data-sal-duration="500"
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
          >
              
               <BackgroundImage
                className=""
                    // Spread bgImage into BackgroundImage:
                {...bgImage}
                
                    style={{
                        // Defaults are overwrite-able by setting one or each of the following:
                      width:'100%',
                      minHeight: height+'px'
                    }}
                ></BackgroundImage>
            <div className="row pageMobileBGimageWrapper d-block d-md-none">
              <BackgroundImage
                className=""
                    // Spread bgImage into BackgroundImage:
                    {...bgImage}
                    style={{
                        // Defaults are overwrite-able by setting one or each of the following:
                      width:'100%',
                      height: '300px',
                    }}
                ></BackgroundImage>
            </div>
                  </div>
          
                  <div className="col " >
              <div className="row" ref={ref}>
                <div className="col-12 col-md-11">
                  <div className="py-md-5 my-md-5">
                    <div className="py-5 my-5"
                      data-sal-duration="500"
                      data-sal="slide-up"
                      data-sal-delay="300"
                      data-sal-easing="ease"
                    >
                  
                    <h2
                      className="fw-light fs-3 my-4"
                      dangerouslySetInnerHTML={{ __html: pageTitle }}
                    />
                    <p className="fw-normal"
                      dangerouslySetInnerHTML={{ __html: pageTitleBody }}
                    />
   
                    </div>
                  </div>
              </div>
            </div>
          </div>

         

          

          </div>
        </div>
      </div>

    )
}

export default TitleBlockStandard
