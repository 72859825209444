import React from 'react';

const Faqs = ({title, data}) => {
    return (
        <div className="container-fluid bg-light py-5 py-md-0 titleBlock" >
            <div className="container">
                <div className="row d-flex flex-column flex-md-row">
                    <div className="col position-relative pageTitleBGOuter mb-5" data-sal-duration="500" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease" >
                        <h2 className="fw-light fs-3 mt-5 mb-4" >{title}</h2>
                        <div class="accordion" id="accordionExample">
           
           {data.length ? (
                    
                    data.map((faq, index) => {
                        
                        return (
                            <div
                                className="accordion-item"
                                key={index}
                            >
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne"+index}  aria-expanded="false"  aria-controls={"#collapseOne"+index}>
                                        {faq.title}
                                    </button>
                                </h2>
                                <div id={"collapseOne"+index} class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        {faq.content}
                                    </div>
                                </div>

                            </div>
                        )
                                
                            
                        })
                        ) : (
                        <>
                            
                        </>
           )}
           

           


                        </div>
                    </div>
                </div>
            </div>
        </div>
   )
}
export default Faqs;