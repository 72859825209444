import React, { useState}  from "react"
import axios from "axios";

import ReCaptchaComp from "../components/recaptcha"



const FooterContact = ({heading,copy,bg }) => {

    //console.log('contact form');
    
    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });
    const handleServerResponse = (ok, msg, form) => {
        //console.log(ok, msg, form)
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };
    const handleOnSubmit = e => {
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/2d8f4845-e170-4ba4-bc6b-9faf3e874352",
        data: new FormData(form)
      })
        .then(r => {
          handleServerResponse(true, "Thank you, someone will be in touch shortly!", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };

  

    return (
        <div className={"container-fluid  py-5 py-md-0 titleBlock bg-"+bg } >
        <div
            className="container py-5 "
            data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
              <div className="row py-5">
                <div className="col-12 col-md-6 d-flex flex-column">
                    <div className="col-md-9">
                        <h2 className="fw-light fs-1 mb-3 ">
                            {heading}
                        </h2>
                    </div> 
                    <div className="mb-5 mb-md-0">
                        <p className="fw-normal mb-0">
                            {copy}
                        </p>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className=""> 
                        
                        <form method="post" onSubmit={handleOnSubmit}>
                        <div className="mb-3">
                            <label htmlFor="formName" className="form-label d-none">Name</label>
                            <input type="text" className="form-control" name="Name"  id="formName" placeholder="Name *" required/>
                            </div>
                        <div className="mb-3">
                            <label htmlFor="formEmail" className="form-label d-none">Email address</label>
                            <input type="email" className="form-control" name="Email" id="formEmail" placeholder="Email *" required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="formPhone" className="form-label d-none">Email address</label>
                            <input type="text" className="form-control" name="Phone" id="formPhone" placeholder="Phone" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="formCompany" className="form-label d-none">Company</label>
                            <input type="text" className="form-control" name="Company" id="formCompany" placeholder="Company"/>
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="formMessage" className="form-label d-none">Message</label>
                            <textarea className="form-control" id="formMessage" name="Message"  placeholder="Message" rows="3"></textarea>
                        </div>
                            <ReCaptchaComp/>

                            <div className="d-grid d-md-block text-end mt-3 ">
                                <button type="submit" className="btn btn-lg btn-light rounded-0">Send Enquiry</button>
                            </div> 
                            {serverState.status && (
                                <div className="mt-3">
                                    <div className={!serverState.status.ok ? "alert alert-danger" : "alert alert-success "}  role="alert">
                                        {serverState.status.msg}
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default FooterContact
