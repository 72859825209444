import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import TitleBlock from "../components/titleBlockPages"
import ImageText from "../components/block_image_text"
import TextText from "../components/block_text_text"
import DesignDeliver from "../components/design-deliver"
import Testimonials from "../components/testimonials"
import ContactForm from "../components/landingContactBlock"
import Faqs from "../components/faqs"


const PromoClothing = ({ pageContext,data }) => {
  ///page details
    const pageDataEdges = pageContext;

    console.log(pageDataEdges)

    const faqs = data.allFaqsJson.nodes[0];
    const testimonialData = data.allTestimonialshomeJson.edges;

    const nodes = testimonialData.map((item) => { 
        return item.node;
    })
    
  return (
    <Layout>
      <Seo
        title={pageDataEdges.title}
        description={"TKings are specialists in customised workwear, uniforms and clothing in the "+ pageDataEdges.localisation +" area." }
      />
        <TitleBlock
        pageTitle={pageDataEdges.title}
        pageSubTitle={"TKings are specialists in customised workwear, uniforms and clothing in the "+ pageDataEdges.localisation +" area." }
        pageTitleBody={"We are your one-stop shop and new business partner for personalised clothing and promotional items in "+ pageDataEdges.localisation +". We specialise in putting your brand on uniforms, workwear, promotional clothing, and other items. At T Kings Associates, we understand the importance of building your brand and making your company stand out. We offer a range of personalised products to help you achieve this goal. By putting your brand on uniforms, workwear, and promotional clothing, you can ensure that your employees and customers represent your company in the best possible way."} 
        img={pageDataEdges.pageTitleImg}
      />
      <ContactForm bg={'primary'} heading={"Get A Quote For Customised Products Across "+ pageDataEdges.localisation } copy={"To get a quote for customised clothing and items in "+ pageDataEdges.localisation +" then fill out our contact form and we’ll be in touch. When we get in touch we’ll ask you basic information about your project, including the type and quantity of clothing or products you require, the design or logo you want to feature, and any special requests or requirements you may have. You can contact us via the contact form, and our team will assist you with your unique project." } />
      <TextText
          pageTitle1={"The Customised Clothing Solutions We Provide In "+ pageDataEdges.localisation}
          pageTitleBody1={"With our industry experience and commitment to quality and customer service, T Kings Associates is the perfect partner for all your uniform and promotional needs. We can help you with the following: <br><br><b>Uniforms</b> <br>​Maintaining a healthy balance between corporate image and team identity across all levels of the organisation is crucial, from sales desk to service engineers. When individuals feel a sense of belonging and alignment with the team, it fosters a positive morale and strengthens the team's overall performance.<br><br><b>Workwear</b><br>Looking for durable and branded workwear to meet the demands of your job site? Look no further! We offer a wide range of ultra-tough and ultra-protective workwear, designed to meet even the most precise specifications. Our workwear reflects the prestige of your brand, ensuring consistency and promoting team unity both on-site and back in the office. With our branded workwear, you can spread brand awareness on the road and keep your workers looking professional and ready for any job."+pageDataEdges.blockImageTextBody}
              
          pageTitle2={"Promotional Clothing"}
          pageTitleBody2={"Looking for promotional clothing for your upcoming event or team outing? Whether you need technical t-shirts for a local 10k, fleeces to keep your team warm at a winter show, or branded flip-flops for a corporate away day, we've got you covered.<br><br><b>Promotional items</b><br>Baseball caps and water bottles, notebooks and tote bags - if it's out there, we can source it and personalise it with print or embroidery. Whether you're planning to distribute giveaways at a trade show, include promotional items in mailings, or replenish office supplies for customers to take home, we've got you covered with a wide selection of items to choose from.<br><br><b>Uniforms With Uniformcity</b><br>At Uniformcity, we take the burden of uniform management off your shoulders, allowing you to focus on what matters most. With our streamlined uniform ordering process, you can save time, space, and money, all while avoiding the hassle of traditional uniform management. Whether you need to outfit a small team or a large workforce, we've got you covered. Our automated system makes it quick and easy to get everything you need with just a few clicks, so you can get back to running your business confidently."}
          />
          
      <ImageText
          pageTitle={"TKings Offers Customised Clothing In The Following Areas In "+ pageDataEdges.localisation }
          pageTitleBody={"We work with companies all across Northamptonshire. Some of the areas we work in are listed below but we work all across the county. Get in touch for more information:<br>"+ pageDataEdges.postCodes}
          img={pageDataEdges.pageAltImg}
      />
      
      <DesignDeliver  sectionName="promotional clothing" />

      <Faqs title={faqs.faqTitle } data={faqs.faqs }/> 
      <Testimonials
        bg={"primary"}
        sectionTitleBlock={'What makes us different?'}
        sectionCopy={'More than just pens & polo shirts, we’re experts in brand expression & lasting impressions.'}
        nodes={nodes}
      />
      
    </Layout>
  )
}
export const query = graphql`
  query MyQuery {
    
    allTestimonialshomeJson {
        edges {
        node {
            name
            copy
            img {
              childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
              }
              
            }
        }
        }
    }

    allFaqsJson {
        nodes {
        faqTitle
            faqs {
                content
                title
            }
        }
    }
}`
export default PromoClothing